import React from 'react'
import ConsultingTemplate from 'components/templates/ConsultingTemplate/ConsultingTemplate'

export default function Consulting231OdVPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = '231 - OdV'

  const description =
    'Il D.Lgs 231/2001 estende alle persone giuridiche la responsabilità per reati commessi in Italia ed all’estero da persone fisiche che operano per la società. In aggiunta alla responsabilità della persona fisica che realizza l’eventuale fatto illecito la normativa ha introdotto la responsabilità in sede penale degli Enti per alcuni reati commessi nell’interesse o a vantaggio degli stessi, da persone che rivestono funzioni di rappresentanza, di amministrazione o di direzione dell’ente o di una sua organizzazione dotata di autonomia finanziaria o funzionale e da persone sottoposte alla direzione o alla vigilanza di uno dei soggetti sopra indicati. <br>A valle del D.Lgs 231/2001 ” Disciplina della responsabilità amministrativa delle persone giuridiche, delle società e delle associazioni anche prive di personalità giuridica” tocca alle aziende ed ai loro organi di amministrazione tutelarsi nella corretta gestione amministrativa dei rapporti interni, con fornitori e clienti. <br><br>Adempiere agli obblighi legislativi che ne derivano richiede, tra l’altro, di: <br><br><ul><li>Adottare, prima della commissione del fatto, modelli organizzativi e gestionali idonei a prevenire reati;</li> <li>Costituire un organismo dell’ente con compito di vigilare efficacemente sul funzionamento e sull’osservanza di modelli e curare il loro aggiornamento;</li> <li>Definire i modelli di organizzazione e gestione;</li> <li>Essere in grado di evitare la commissione del reato se non mediante l’elusione fraudolenta dei modelli stessi;</li> <li>Individuare le attività nel cui ambito possono essere commessi tali reati;</li> <li>Individuare modalità di gestione delle risorse finanziarie idonee ad impedire la commissione di reati;</li> <li>Prevedere specifici protocolli diretti a programmare la formazione e l’attuazione delle decisioni dell’ente in relazione ai reati da prevenire.</li></ul>'

  return (
    <>
      <ConsultingTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
